.join-room {
  min-width: 80vw;
  height: 80vh;
  margin: 4%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.joinee-video {
  min-width: 60vw;
  min-height: 70vh;
  background-color: rgb(102, 214, 214);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3px solid;
  margin-bottom: 3%;
}

.joinee-video-element {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.joinee-user-media {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2%;
}

.joinee-media-video {
  background-color: antiquewhite;
  min-height: 6vh;
  min-width: 18vw;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 10px;
  font-weight: 500;
  white-space: nowrap;
}

.joinee-media-mic {
  margin-left: 10%;
  background-color: antiquewhite;
  min-height: 6vh;
  min-width: 18vw;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 10px;
  font-weight: 500;
  white-space: nowrap;
}

.join-button {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 6vh;
  min-width: 18vw;
  border: none;
  border-radius: 10px;
  font-weight: 800;
  font-size: 1vw;
  padding: 1%;
  background-color: rgb(200, 218, 218);
  cursor: pointer;
  color: #195b5b;
  text-align: center;
}

.join-button:hover {
  background-color: rgb(138, 184, 184);
}
