.tooltip-content {
  position: absolute;
  top: 20px;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 10px;
  border-radius: 5px;
  z-index: 2000;
  width: 300px;
  overflow-y: auto;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.params-entries {
  display: flex;
  margin-bottom: 2%;
}
