.App {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.app-container {
  margin: 2% 2% 1% 2%;
  width: 80vw;
  height: 90vh;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  background-color: #282c34;
  align-items: center;
  position: relative;
}

.peer-container {
  background-color: rgb(104, 148, 186);
  max-width: 98%;
  max-height: 45%;
  margin: 1% 0% 1% 0%;
  min-width: 30%;
  min-height: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1%;
}

.next-prev-buttons {
  display: flex;
  flex-direction: column;
}

.next-prev-buttons button {
  border: none;
  background-color: #282c34;
  margin-bottom: 15%;
  min-width: 4vw;
  min-height: 5vh;
  color: antiquewhite;
  border-radius: 5%;
  text-align: center;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 550;
  cursor: pointer;
  white-space: nowrap;
}

.peer-container:nth-child(odd) {
  color: rgb(60, 60, 96);
  background-color: #61dafb;
  border: 2px #56676c solid;
}

.peer-container:nth-child(even) {
  color: #ffff;
  background-color: #56676c;
  border: 2px #61dafb solid;
}

.info-tool-tip {
  position: absolute;
  top: 4%;
  right: 5%;
  cursor: pointer;
  background-color: #333;
  color: white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

.help-tag {
  text-decoration: underline;
  color: rgb(219, 219, 160);
  cursor: pointer;
}

.info-icon{
  width: auto;
  height: 100%;
}