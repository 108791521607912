body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.help {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.help-heading {
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  margin-top: 2%;
  color: #0a0a49;
  font-size: 2vw;
}

.content-section {
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: flex-start;
  font-family: panton, sans-serif;
  margin-left: 2%;
}

.para-1,
.para-2 {
  text-align: left;
  max-width: 90%;
}

.para-3 {
  text-align: left;
  color: blue;
}
.para-1 {
  margin-bottom: -0.2%;
}

.link-block {
  border-color: aqua;
  border: solid;
  width: fit-content;
  padding: 10px;
  text-decoration: underline;
  border-radius: 10px;
  border-color: #f5ea14;
  border-width: 2px;
}

.get-started {
  color: #0a0a49;
}

.point-div {
  display: flex;
  flex-direction: column;
  text-align: left;
}
